<template>
<v-container fluid fill-height>
    <v-card :class="`align-self-stretch ma-2 ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 justify-space-between">
            <h1>Налаштування API</h1>
        </v-card-title>
        <v-card-text class="mt-4 px-0">
            <p class="body-1">Доступ до API надається за авторизацією по ключу та за умови звертання з дозволеної IP-адреси.</p>
            <h2 class="second--text mb-6 mt-10">Ключі авторизації</h2>
            <p class="font-italic">* Будьте уважні! Повторне генерування пари ключів потребує зміни налаштувань в ваших скриптах.</p>
            <v-card class="mb-4 blue lighten-5 px-0 px-md-2">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" class="py-0">
                            <v-row>
                                <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                                    <v-text-field :value="secretPare.user_key" label="user_key" type="text" readonly></v-text-field>
                                    <v-btn icon @click="clickHandler(1)" title="Копіювати" v-clipboard="() => secretPare.user_key" v-clipboard:success="clipboardSuccessHandler">
                                        <v-icon>{{copied[1] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                                    <v-text-field :value="secretPare.secret" @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" label="secret" readonly></v-text-field>
                                    <v-btn icon @click="clickHandler(2)" title="Копіювати" v-clipboard="() => secretPare.secret" v-clipboard:success="clipboardSuccessHandler">
                                        <v-icon>{{copied[2] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="this.userInfo().is_wholesale_onbehalf_ok" @click="secretPare.user_key? dialog = !dialog : createSecretPare()" color="primary">Згенерувати</v-btn>
                </v-card-actions>
            </v-card>
            <p>Для використання ключів скористайтеся <a @click="$router.push('/api-services/get-auth-token')">посиланням</a>.</p>

            <h2 class="second--text mb-6 mt-10">IP-адреси</h2>
            <v-alert v-if="is_withoutIP" type="success" text outlined dense :width="$vuetify.breakpoint.mdAndUp ? `50%` : `100%`">Дозволено доступ з будь-якої IP-адреси.</v-alert>
            <v-card v-if="!is_withoutIP && allowedUserIp && allowedUserIp.length" class="mb-4 blue lighten-5 px-0 px-md-2">
                <v-card-text>
                    <p>Доступ до API дозволено з наступних IP-адрес:</p>
                    <ul>
                        <li v-for="(el, key) of allowedUserIp" :key="key" class="second--text">{{el.ip}}</li>
                    </ul>
                    <div v-if="!is_withoutIP && !allowedUserIp.length">
                        <v-alert type="error" text outlined dense :width="$vuetify.breakpoint.mdAndUp ? `50%` : `100%`">Немає дозволених IP-адрес.</v-alert>
                        <p>Для внесення нової IP-адреси у перелік дозволених зверніться до менеджера компанії.</p>
                    </div>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="600">
        <v-card>
            <v-card-title class="primary--text pb-8">
                Увага!
            </v-card-title>
            <v-card-text>
                <p>Повторне генерування пари ключів потребує зміни налаштувань в ваших скриптах.</p>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialog = false">Відмовитись</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="createSecretPare()" color="primary">Згенерувати нову пару</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {},
    directives: {},
    data: () => ({
        show1: false,
        dialog: false,
        copied: {},
    }),
    props: {},
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'createSecret', 'getSecretPare', 'getAllowedUserIp']),
        ...mapGetters(['userInfo']),        
        createSecretPare() {
            this.touch()
                .then(() => {
                    this.createSecret()
                        .then(() => this.setSnackbar(['success', 'Нову пару ключів сгенеровано']))
                })
                .catch(error => console.log(error))
            this.dialog = false
        },
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },

    },
    computed: {
        secretPare() {
            return this.$store.state.main.secretPare
        },
        allowedUserIp() {
            return this.$store.state.main.allowedUserIp
        },
        is_withoutIP() {
            return this.$store.state.main.is_withoutIP
        }
    },
    created() {
        this.touch()
            .then(() => {
                this.getSecretPare()
                this.getAllowedUserIp()
            })
            .catch(error => console.log(error))
    }
}
</script>

<style>
</style>
